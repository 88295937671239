<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
            <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
               <div>
                  <h4 class="mb-3">{{title}}</h4>
                  <p class="mb-0">{{desc1}}<br>{{desc2}}</p>
               </div>
            </div>
         
            <card>
               <div class="p-3">
                  <form  @submit.prevent="submit" >
                        <div class="row">

                           <div class="col-lg-3">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="바코드" v-model="바코드">
                              </div>
                           </div> 
                           <div class="col-lg-3">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="SKU ID" v-model="SKUID">
                              </div>
                           </div>
                           <div class="col-lg-3">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="발주번호" v-model="발주번호">
                              </div>
                           </div>
                           <div class="col-lg-3">
                              <div class="form-group">
                                    <input type="text" class="form-control" placeholder="발주센터" v-model="발주센터">
                              </div>
                           </div>
                                                               
                        </div>                            
                        <button type="button" class="btn btn-primary mr-2 mt-1" @click="searchdata">검색</button>
                        <div class="row" v-if="showspinner">
                           <div class="col-md-3 text-center">
                              <img style="width:20%;" :src="require('../../../assets/images/small/loading.gif')">
                              <span>데이터 계산중...</span>
                           </div>
                        </div>
                  </form>
               </div>
            </card>

            <div class="col-lg-12 mb-4" v-if="showlist">
               <div class="row">
                  <b-button variant="outline-primary" class="mb-1 mr-2" @click="showtotal">
                  전체<b-badge variant="primary" class="ml-2">{{total}}</b-badge>
                  </b-button>
                  <b-button variant="outline-light" class="mb-1 mr-2" @click="showa">
                  구매(발송전)<b-badge variant="light" class="ml-2">{{발송전}}</b-badge>
                  </b-button>
                  <b-button variant="outline-warning" class="mb-1 mr-2" @click="showb">
                  구매(발송후)<b-badge variant="warning" class="ml-2">{{발송후}}</b-badge>
                  </b-button>
                  <b-button variant="outline-primary" class="mb-1 mr-2" @click="showc">
                  해외창고<b-badge variant="primary" class="ml-2">{{해외창고}}</b-badge>
                  </b-button>
                  <b-button variant="outline-danger" class="mb-1 mr-2" @click="showd">
                  해외출고<b-badge variant="danger" class="ml-2">{{해외출고}}</b-badge>
                  </b-button>                         
                  <b-button variant="outline-secondary" class="mb-1 mr-2" @click="showe">
                  국내창고<b-badge variant="secondary" class="ml-2">{{국내창고}}</b-badge>
                  </b-button> 
                  <b-button variant="outline-success" class="mb-1 mr-2" @click="showf">
                  국내출고<b-badge variant="success" class="ml-2">{{국내출고}}</b-badge>
                  </b-button>
                  <b-button variant="outline-warning" class="mb-1 mr-2" @click="showg">
                  쿠팡재고<b-badge variant="warning" class="ml-2">{{쿠팡재고}}</b-badge>
                  </b-button>
               </div> 
            </div>
         </div>

			<div class="col-lg-12" v-if="showlist">
				<div class="table-responsive rounded mb-3">
					<tableList>
						<template v-slot:header>
							<tr class="ligth ligth-data">
                        <th>상품정보</th>
                        <th>재고Total</th>
                        <th>구매(발송전)</th>
                        <th>구매(발송후)</th>
								<th>해외창고</th>
								<th>해외출고</th>
                        <th>국내창고</th>
                        <th>국내출고</th>
								<th>쿠팡재고</th>
							</tr>
						</template>
						<template v-slot:body>
							<!-- <tr v-b-modal="modalID(index)" v-for="(stocklist,index) in stocklists" :key="index"> -->
                     <tr v-for="(stocklist,index) in stocklists" :key="index">
                        <td style="min-width:300px;">
                           <div class="d-flex text-left">
                                 <img :src="stocklist.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                 <div>
                                    <p class="mb-0"><small>{{stocklist.상품명}}</small> / <small>{{stocklist.바코드}}</small> / <small>{{stocklist.SKUID}}</small></p>
                                 </div>
                           </div>
                        </td>
                        <td style="font-size:200%;">{{stocklist.전체}}</td>
                        <td>{{stocklist.구매발송전 == 0 ? '' : stocklist.구매발송전}}</td>
                        <td>{{stocklist.구매발송후 == 0 ? '' : stocklist.구매발송후}}</td>
                        <td>{{stocklist.해외창고 == 0 ? '' : stocklist.해외창고}}</td>
                        <td>{{stocklist.해외출고 == 0 ? '' : stocklist.해외출고}}</td>
                        <td>{{stocklist.국내창고 == 0 ? '' : stocklist.국내창고}}</td>
                        <td>{{stocklist.국내출고 == 0 ? '' : stocklist.국내출고}}</td>
                        <td>{{stocklist.쿠팡재고 == 0 ? '' : stocklist.쿠팡재고}}</td>


<!-- 
                           <b-modal :id="'modal'+index" size="xl" title="상품 상세 정보" ok-title="OK" cancel-title="Close">
                              <div class="col-lg-12">
                                 <div class="table-responsive rounded mb-3">
                                    <tableList>
                                       <template v-slot:header>
                                          <tr class="ligth ligth-data">
                                             <th>상품정보</th>
                                             <th>재고Total</th>
                                             <th>구매(발송전)</th>
                                             <th>구매(발송후)</th>
                                             <th>해외창고</th>
                                             <th>해외출고</th>
                                             <th>국내창고</th>
                                             <th>국내출고</th>
                                             <th>쿠팡재고</th>
                                          </tr>
                                       </template>
                                       <template v-slot:body>
                                          <tr v-b-modal="modalID(index)" v-for="(stocklist,index) in stocklists" :key="index">
                                             <td style="min-width:300px;">
                                                <div class="d-flex align-items-center">
                                                      <img :src="stocklist.등록이미지" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                      <div>
                                                      {{stocklist.상품명}}
                                                      <p class="mb-0"><small>{{stocklist.바코드}}</small></p>
                                                      <p class="mb-0"><small>{{stocklist.SKUID}}</small></p>
                                                      </div>
                                                </div>
                                             </td>
                                             <td>{{stocklist.total}}</td>
                                             <td>{{stocklist.po_before}}</td>
                                             <td>{{stocklist.po_after}}</td>
                                             <td>{{stocklist.cn}}</td>
                                             <td>{{stocklist.cn2kr}}</td>
                                             <td>{{stocklist.kr}}</td>
                                             <td>{{stocklist.kr2co}}</td>
                                             <td>{{stocklist.co}}</td>
                                          </tr>
                                       </template>
                                    </tableList>
                                 </div>
                                 <button type="button" class="btn btn-primary mr-2 mb-4" @click="download">다운로드</button>
                                 <button type="button" class="btn btn-secondary mr-2 mb-4" @click="deletestock">삭제</button>
                                 <div class="float-right"><input type="text" class="form-control" placeholder="Page검색" v-model="pagenumber" @keyup.enter="pagemv($event)"></div>
                              </div>
                           </b-modal> -->



							</tr>
						</template>
					</tableList>
				</div>
            <button type="button" class="btn btn-primary mr-2 mb-4" @click="download">다운로드</button>
            <!-- <button type="button" class="btn btn-secondary mr-2 mb-4" @click="deletestock">삭제</button> -->
            <div class="float-right"><input type="text" class="form-control" placeholder="Page검색" v-model="pagenumber" @keyup.enter="pagemv($event)"></div>
			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
// import { mapState } from 'vuex';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { core } from '../../../config/pluginInit'
export default {
    name:'ListSale',
    data(){
        return{
            title:"재고리스트(전체)",
            desc1:"구매(발송전) → 구매(발송후) → 해외창고 → 해외출고 → 국내창고 → 국내출고 → 쿠팡재고",
            desc2:"'재고Total = 해외창고 + 해외출고 + 국내창고 수량의 합입니다.'",
            link:{ name: 'sale.totallistsale'},
            linktext:"재고등록",
            badge : 'badge',
            // selectinfo: 'total',

            showspinner: false,

            selectoption: '입고일시',
            startdate: this.getstartdate(),
            enddate: this.getTodaywith(),
            // 재고박스번호: '',
            // 배치박스번호: '',
            // 파렛트번호: '',
            바코드: '',
            SKUID: '',
            발주번호: '',
            발주센터: '',
            // 입고송장번호: '',

            checkbox1:false,
           
            stocklists : [],
            stocklistsinit : [],
            showlist: false,

            notarrangedcnt: 0,

            //배치박스(boxlist 정보)
            qty: [],
            boxnumber: [],
            palletnumber: [],
            
            total: 0,
            발송전: 0,
            발송후: 0,
            해외창고: 0,
            해외출고: 0,
            국내창고: 0,
            국내출고: 0,
            쿠팡재고: 0,

            pagenumber: '',
        }
    },
    mounted(){
      // this.search();
    },	
    methods : {
      currentpage(){
         document.getElementsByClassName('paginate_button')
      },
      pagemv(event){
         if(event.keyCode === 13){
            core.mvpage(this.pagenumber)
			}
      },
      showtotal(){
         this.showlist = false;
         setTimeout(() => {
            this.stocklists = this.stocklistsinit;
            this.showlist = true;            
         }, 10);
      },
      showa(){
         this.showlist = false;
         setTimeout(() => {
            this.stocklists = this.stocklistsinit.filter(e => e.구매발송전 > 0);
            this.showlist = true;            
         }, 10);
      },
      showb(){
         this.showlist = false;
         setTimeout(() => {
            this.stocklists = this.stocklistsinit.filter(e => e.구매발송후 > 0);
            this.showlist = true;            
         }, 10);
      },
      showc(){
         this.showlist = false;
         setTimeout(() => {
            this.stocklists = this.stocklistsinit.filter(e => e.해외창고 > 0);
            this.showlist = true;            
         }, 10);
      },
      showd(){
         this.showlist = false;
         setTimeout(() => {
            this.stocklists = this.stocklistsinit.filter(e => e.해외출고 > 0);
            this.showlist = true;            
         }, 10);
      },
      showe(){
         this.showlist = false;
         setTimeout(() => {
            this.stocklists = this.stocklistsinit.filter(e => e.국내창고 > 0);
            this.showlist = true;            
         }, 10);
      },
      showf(){
         this.showlist = false;
         setTimeout(() => {
            this.stocklists = this.stocklistsinit.filter(e => e.국내출고 > 0);
            this.showlist = true;            
         }, 10);
      },
      showg(){
         this.showlist = false;
         setTimeout(() => {
            this.stocklists = this.stocklistsinit.filter(e => e.쿠팡재고 > 0);
            this.showlist = true;            
         }, 10);
      },                              
      checkallbox(){
         if(this.checkbox1){
            this.stocklists.forEach(e => e.checked = true)
         } else {
            this.stocklists.forEach(e => e.checked = false)
         }
      },
      deletestock(){
         if(this.stocklists.filter(e => e.checked).length == 0){
            alert("삭제할 제품을 선택해 주세요")
            return
         }
			if(confirm("선택하신 재고를 삭제하시겠습니까?") == true){
				this.showlist = false;
				var dellist = this.stocklists.filter(e => e.checked);
            
            this.stocklistsinit = this.stocklistsinit.filter(e => dellist.filter(ele => ele.id == e.id).length == 0);
				this.stocklists = this.stocklists.filter(e => !e.checked);
				setTimeout(() => {
					this.deletestockdb(dellist)
					this.$store.commit('stockdelete',dellist);
					this.showlist = true;
				}, 1);

			} else {
				return false
			}

		},
      deletestockone(index){
         console.log(this.stocklists)
         if(confirm("해당 재고를 삭제하시겠습니까?") == true){
            var arr = [];
            arr.push(this.stocklists[index]);
            this.deletestockdb(arr)
            this.$store.commit('stockdelete',arr);
            this.stocklists = this.deletelist(this.stocklists,index)
         }
      },
      deletestockdb(arr){
			axios.post('/api/deletestock',arr)
			.then((res) => {
				if(res.data.요청결과 == "삭제완료"){
					console.log(res.data)
				} else {
					alert("삭제실패")
					console.log(res.data)
               return false
				}
			})	
			.catch((e) => {alert("업데이트 실패");console.log(e);return false})
		},
      deletelist(array,i){
         return array.slice(0,i).concat(array.slice(i+1,array.length))
      },
      async searchdata(){
         this.search();
      },
      async search(){
         this.showspinner = true;
         setTimeout(async () => {
            this.showlist = false;
            this.stocklists = [];
            this.stocklistsinit = [];
            var searchobj = {
               companyid : this.$store.state.user.companyid,
               start : this.getTodaywith(),
               end : this.getTodaywith(),
            };

            var res = await axios.post('/api/listsales',searchobj).catch((e) => {alert("업데이트 실패");console.log(e);return false})
            var costock = await res.data.sales;
            

            var arr = [];
            var obj = {};
            var ar = [];
            this.$store.state.productdb.forEach(e => {

               var 구매발송전 = 0;
               var 구매발송후 = 0;
               if(this.$store.state.purchase.filter(ele => ele.바코드 == e.바코드).length > 0){
                  ar = this.$store.state.purchase.filter(ele => ele.바코드 == e.바코드);
                  구매발송전 = ar.filter(ele => ele.배송상태 == '발송전' && ele.배송상태 == '발송지연').reduce((pv,cv) => {return pv + (cv.주문수량/(cv.판매구성수량 == '단품' ? 1 : parseInt(cv.판매구성수량)))},0);
                  구매발송후 = ar.filter(ele => ele.배송상태 !== '입고완료' && ele.배송상태 !== '발송전' && ele.배송상태 !== '발송지연').reduce((pv,cv) => {return pv + (cv.주문수량/(cv.판매구성수량 == '단품' ? 1 : parseInt(cv.판매구성수량)))},0);
               }
               
               var 해외창고 = 0;
               var 해외출고 = 0;
               var 국내창고 = 0;
               var 국내출고 = 0;
               if(this.$store.state.stock.filter(ele => ele.바코드 == e.바코드).length > 0){
                  ar = this.$store.state.stock.filter(ele => ele.바코드 == e.바코드)
                  해외창고 = ar.filter(ele => ele.site == 'cn').length;
                  해외출고 = ar.filter(ele => ele.site == 'cn2kr').length;
                  국내창고 = ar.filter(ele => ele.site == 'kr').length;
                  국내출고 = ar.filter(ele => ele.site == 'kr2co').length;
               }         

               var 쿠팡재고 = 0;
               if(costock.length > 0 && costock.판매세부내역 && costock.판매세부내역.find(ele => ele.sk == e['SKU ID'])){
                  쿠팡재고 = costock.판매세부내역.find(ele => ele.sk == e['SKU ID']).s
               }     

               obj = {
                  SKUID : e['SKU ID'],
                  바코드 : e.바코드,
                  상품명 : e.상품명,
                  등록이미지 : e.등록이미지,
                  전체 : 구매발송전 + 구매발송후 + 해외창고 + 해외출고 + 국내창고 + 국내출고 + 쿠팡재고 ? 구매발송전 + 구매발송후 + 해외창고 + 해외출고 + 국내창고 + 국내출고 + 쿠팡재고 : 0,
                  구매발송전 : 구매발송전 ? 구매발송전 : 0,
                  구매발송후 : 구매발송후 ? 구매발송후 : 0,
                  해외창고 : 해외창고 ? 해외창고 : 0,
                  해외출고 : 해외출고 ? 해외출고 : 0,
                  국내창고 : 국내창고 ? 국내창고 : 0,
                  국내출고 : 국내출고 ? 국내출고 : 0,
                  쿠팡재고 : 쿠팡재고 ? 쿠팡재고 : 0,
               }
               // console.log(obj)
               arr.push(obj)
            })

            this.total = arr.reduce((pv,cv) => {return pv + cv.전체},0);
            this.발송전 = arr.reduce((pv,cv) => {return pv + cv.구매발송전},0);
            this.발송후 = arr.reduce((pv,cv) => {return pv + cv.구매발송후},0);
            this.해외창고 = arr.reduce((pv,cv) => {return pv + cv.해외창고},0);
            this.해외출고 = arr.reduce((pv,cv) => {return pv + cv.해외출고},0);
            this.국내창고 = arr.reduce((pv,cv) => {return pv + cv.국내창고},0);
            this.국내출고 = arr.reduce((pv,cv) => {return pv + cv.국내출고},0);
            this.쿠팡재고 = arr.reduce((pv,cv) => {return pv + cv.쿠팡재고},0);
               
            this.stocklists = arr.filter(e => e.전체 > 0)
            this.stocklistsinit = arr.filter(e => e.전체 > 0)
            if(costock.length == 0){
               alert('couplus 확장프로그램을 통해 오늘자 판매현황을 수집하면 쿠팡재고 확인이 가능합니다.')
            }
            this.showspinner = false;
            this.showlist = true;
         },1)
         
      },
      openfindlist(barcode){
			var arrangecnt = 0;
			var notarrangecnt = 0;
			var pstockcnt = 0;
			var rstockcnt = 0;
			const bar = barcode;
			if(this.$store.state.productdb.find(e => e.바코드 == bar) === undefined){
				alert("존재하지 않는 바코드 입니다")
			} else {
				
					//바코드 찾는 함수
					var result = [];
					var temp = {};
					this.$store.state.polist.forEach((e) => {
						if(!e.발주상태.includes("거래명세서")) {
							e.발주세부내역.some((ele) => {
								if(ele.바코드 == bar){
									arrangecnt = 0;
									notarrangecnt = 0;
									pstockcnt = 0;
									rstockcnt = 0;
									if (this.$store.state.stock.length != 0){
										this.$store.state.stock.forEach(element => {
											if(element.바코드 == ele.바코드){
												if(element.배치발주번호 == e.발주번호){
													arrangecnt++
												}
											}
										})
									}
									ele.발주수량 = parseInt(ele.발주수량);
									ele.입고수량 = parseInt(ele.입고수량);
									if(isNaN(ele.입고수량)){
										ele.입고수량 = 0;
									}
									ele.확정수량 = parseInt(ele.확정수량);
									ele.배치수량 = arrangecnt;
									ele.미배치재고수량 = notarrangecnt;
									ele.구매완료수량 = pstockcnt;
									ele.회송예정수량 = rstockcnt;
									ele.사입재고수량 = 0;
									if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
										this.$store.state.productdb.some(element => {
											if(ele.바코드 == element.바코드 && element.구매링크 == '사입'){
												ele.사입재고수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt;
												return (ele.바코드 == element.바코드 && element.구매링크 == '사입')
											}
										})
									}
									ele.구매필요수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt - ele.사입재고수량;
									ele.필요수량 = ele.확정수량 - ele.배치수량


									if(ele.필요수량 > 0){
										temp = this.deepClone(e);
										temp.발주세부내역 = ele;
										result.push(temp);
										temp = {};
										return (ele.바코드 == bar && ele.필요수량 > 0)
									}
								}
							});
						}
					})
					//발주센터로 먼저 정렬
					result.sort(function(a,b){
						if (a.발주센터 < b.발주센터){
							return -1;
						}
						if (a.발주센터 > b.발주센터){
							return 1;
						}
						return 0;
					})
					//입고예정일순으로 최종 정렬
					result.sort(function(a,b){
						return new Date(a.입고예정일) - new Date(b.입고예정일);
					})
					console.log(result)
               
               var num
					result.forEach((a,i) => {
						this.qty[i] = 0;
						num = this.$store.state.stock.filter(e => e.site == 'kr').filter(e => e.배치발주센터 == a.발주센터 && e.배치입고예정일 == a.입고예정일).reduce((acc, shot) => acc = acc > shot.배치박스번호 ? acc : shot.배치박스번호, 0);
						console.log(num)
						if (num == 0 || isNaN(num)){
							var newone = parseInt(this.maxvalue(this.$store.state.stock.filter(e => e.site == 'kr'),'배치박스번호')) + 1;
							console.log(newone)
							this.boxnumber.push(parseInt(newone));
							this.palletnumber.push('');
						} else {
							this.boxnumber.push(parseInt(num));
							this.palletnumber.push(this.$store.state.stock.filter(e => e.site == 'kr').find(e => e.배치박스번호 == num).배치파렛트번호);
						}
						
					})

					if(result.length > 0){
						// 발주번호, 발주센터, 입고예정일, badge(배치필요) 추가 -------------------------------------------------------------->수정중
					}
				
			}
		},    
      maxvalue(data,key){
			return data.reduce((prev, current) => (prev[key] > current[key]) ? prev : current)[key]
		},  
      getTodaywith(){
         var date = new Date();
         var year = date.getFullYear();
         var month = ("0" + (1 + date.getMonth())).slice(-2);
         var day = ("0" + date.getDate()).slice(-2);

         return year + "-" + month + "-" + day;
      },  
      getstartdate(){
         var date = new Date();
         var year = date.getFullYear()-1;
         var month = ("0" + (1 + date.getMonth())).slice(-2);
         var day = ("0" + date.getDate()).slice(-2);

         return year + "-" + month + "-" + day;
      },        
      modalID(index) {
         return 'modal' + index
      },
      modalIDedit(index) {
         return 'modaledit' + index
      },      
      deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}
			const result = Array.isArray(obj) ? [] : {}
			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}
			return result
      },
      changestockinfo(stockinfo){
         if(stockinfo == "stock") {
            return "재고"
         }
         if(stockinfo == "Astock") {
            return "배치재고"
         }
         if(stockinfo == "Pstock") {
            return "구매재고"
         }
         if(stockinfo == "Rstock") {
            return "회송재고"
         }                      
      },
      changeboxinfo(stockinfo,재고박스번호,배치박스번호){
         if(stockinfo == "stock") {
            return 재고박스번호
         }
         if(stockinfo == "Astock") {
            return 배치박스번호
         }
         if(stockinfo == "Pstock") {
            return "구매입고전"
         }
         if(stockinfo == "Rstock") {
            return "회송입고전"
         } 
      },
      changepalletinfo(stockinfo,재고파렛트번호,배치파렛트번호) {
         if(stockinfo == "stock") {
            return 재고파렛트번호
         }
         if(stockinfo == "Astock") {
            return 배치파렛트번호
         }
         if(stockinfo == "Pstock") {
            return "구매입고전"
         }
         if(stockinfo == "Rstock") {
            return "회송입고전"
         }            
      },
      badgecolor(stockinfo){
         if(stockinfo == "stock") {
            return "badge badge-primary"
         }
         if(stockinfo == "Astock") {
            return "badge badge-success"
         }
         if(stockinfo == "Pstock") {
            return "badge badge-secondary"
         }
         if(stockinfo == "Rstock") {
            return "badge badge-danger"
         } 
      },
      timestamp(date){
         //inputdate = "yyyy-mm-dd"
         return new Date(date).getTime() / 1000
      },
      download() {
         var arr = [];
         if(this.stocklists.filter(e => e.checked).length == 0){
            arr = this.stocklists
         } else {
            arr = this.stocklists.filter(e => e.checked)
         }
         function getToday(){
               var date = new Date();
               var year = date.getFullYear();
               var month = ("0" + (1 + date.getMonth())).slice(-2);
               var day = ("0" + date.getDate()).slice(-2);

               return year + month + day;
         }

         const workBook = XLSX.utils.book_new()
         const workSheet1 = XLSX.utils.json_to_sheet(arr)
         XLSX.utils.book_append_sheet(workBook, workSheet1, '재고현황(전체)')
         XLSX.writeFile(workBook, '재고현황(전체)' + '_' + getToday() + '.xlsx')
      }
    }
}
</script>
<style scoped>
.modal-dialog {
    max-height: 90%;
    position: absolute; 
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
